import cx from 'classnames';
import { getInitialLocale } from '@lib/translations/getInitialLocale';
import { navigationDataFilter, validationRules } from '@constants/index';

export const openInNewTab = url => url && window.open(url, '_blank');
/**
 * Retrieves text classes by textColor param.
 * @method
 * @param {Text} label
 * @param {Boolean} required flag for validation
 * @param {Text} Required ErrorMessage for Form item
 * @param {Text} validationErrorMessage for the Form item
 * @returns {Object} Returns Validation object.
 */

export const formValidation = (
  label,
  requiredErrorMessage,
  validationErrorMessage,
  required = false
) => {
  const { emailRegex, nameRegex, numberRegex, zipPostalRegex } =
    validationRules;
  const validationRule = required => {
    let returnValue = required;
    if (required) {
      returnValue =
        requiredErrorMessage || 'This box must be checked to proceed.';
    }
    return returnValue;
  };
  const validation = {
    required: validationRule(required)
  };
  if (label.includes('number')) {
    validation.pattern = {
      value: numberRegex,
      message: validationErrorMessage
    };
  }
  if (
    (label.includes('zip') && label.includes('postal')) ||
    label.includes('zip')
  ) {
    validation.pattern = {
      value: zipPostalRegex,
      message: validationErrorMessage
    };
  }
  if (label.includes('email')) {
    validation.pattern = {
      value: emailRegex,
      message: validationErrorMessage
    };
  }
  if (label.includes('first') || label.includes('last')) {
    validation.pattern = {
      value: nameRegex,
      message: validationErrorMessage
    };
  }
  return validation;
};

/**
 * Retrieves text classes by textColor param.
 * @method
 * @param {Text} textColor - Text color
 * @returns {Text} Returns Text color classes
 */
export const getTextColorClasses = textColor => {
  {
    switch (textColor) {
      case 'Black':
        return 'text-black';
      case 'White':
        return 'text-white';
      case 'Kohler Blue':
        return 'text-primary';
      default:
        return '';
    }
  }
};

/**
 * Retrieves Background classes by backgroundColor param.
 * @method
 * @param {Text} backgroundColor - Background color
 * @returns {Text} Returns Background color classes
 */
export const getBgColorClasses = backgroundColor => {
  {
    switch (backgroundColor) {
      case 'Black':
        return 'bg-black';
      case 'White':
        return 'bg-white';
      case 'Kohler Blue':
        return 'bg-primary';
      case 'Dark Gray':
        return 'bg-neutrals-gray-700';
      case 'Gray':
        return 'bg-neutrals-gray-600';
      case 'Medium Gray':
        return 'bg-neutrals-gray-400';
      case 'Light Gray':
        return 'bg-neutrals-gray200';
      default:
        return 'bg-transparent';
    }
  }
};

/**
 * Retrieves alignment classes by alignment param.
 * @method
 * @param {Text} alignment - Alignment type
 * @returns {Text} Returns alignment classes
 */
export const alignmentClasses = alignment => {
  {
    switch (alignment) {
      case 'Left':
        return 'text-left';
      case 'Right':
        return 'text-right';
      case 'Center':
        return 'text-center';
      default:
        return '';
    }
  }
};

/**
 * Retrieves navigation data by section filter param.
 * @method
 * @param {Object} navigation data
 * @param {Text} section - section type
 * @returns {Text} Returns navigation data
 */
export const getNavigationData = (navigationData, section) => {
  {
    switch (section) {
      case navigationDataFilter.header:
        return {
          primaryNavHeaderData:
            navigationData?.primaryNav?.fields?.navigationItems || [],
          secondaryNavHeaderData:
            navigationData?.secondaryNav?.fields?.navigationItems || []
        };
      case navigationDataFilter.footer:
        return {
          copyright: navigationData?.copyright,
          primaryNavFooterData:
            navigationData?.primaryNavigation?.[0]?.fields?.navigationItems ||
            [],
          secondaryNavFooterData:
            navigationData?.secondaryNavigation?.fields?.navigationItems || []
        };
      default:
        return {};
    }
  }
};

/**
 * Retrieves error message based on status code.
 * @method
 * @param {Text} Status code
 * @returns {Object} Returns heading data
 */
export const showErrorMessage = statusCode => {
  {
    switch (statusCode) {
      case 404:
        return {
          subHeading: "We can't find that page.",
          body: (
            <span>
              Try searching or view our{' '}
              <a
                href={`/sitemap`}
                className='underline underline-offset-4 decoration-[1.5px] mr-2'
              >
                Site Map
              </a>
              to power back up.
            </span>
          )
        };
      case 502:
        return {
          subHeading: 'The server encountered a temporary error.',
          body: "Don't worry, we're backed up by a KOHLER generator and will be back soon."
        };
      case 503:
        return {
          subHeading:
            'The website is under maintenance and will be powered back up soon.',
          body: 'Try refreshing the page or try again later.'
        };
      default:
        return {
          subHeading: 'We are working on the issue.',
          body: 'Try refreshing the page or try again later.'
        };
    }
  }
};

/**
 * Retrieves error message based on status code.
 * @method
 * @param {Text} Text
 * @returns {Text} Returns camelize text
 */
export const camalize = str => {
  let output = str;
  if (typeof str === 'string') {
    output = str
      .toLowerCase()
      .replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
  }
  return output;
};

export function urlFormatter(url) {
  if (typeof window !== 'undefined') {
    return url.includes('https://') || url.includes('policy')
      ? url
      : `/${getInitialLocale()}/${url}`;
  }
}

export const stopScroll = e => {
  e.preventDefault();
};

export const getUrlOpenerType = urlOpenerType =>
  urlOpenerType == 'New Tab' ? '_blank' : undefined;
export const getHref = (urlType, mediaUrl, cardCTALink) =>
  urlType === 'Media' ? `https:${mediaUrl}` : cardCTALink || '#';

export const getCardClasses = (
  normalRow,
  centeredRows,
  rightSideImage,
  leftSideImage,
  bottomSideImage
) => {
  const containerClass = cx(
    'shadow-card relative bg-white',
    {
      'grid-cols-1 h-full group': normalRow
    },
    {
      'grid lg:grid-cols-2 md:grid-cols-2': rightSideImage
    },
    {
      'lg:w-96 m-3 group overflow-hidden rounded': centeredRows
    },
    {
      'grid lg:grid-cols-2 group grid-cols-1 grow items-stretch': leftSideImage
    }
  );

  const imageHolderClasses = cx(
    'img-container',
    {
      'h-auto grid col-span-1': bottomSideImage
    },
    {
      'grid order-first md:col-span-1 md:order-first': leftSideImage
    },
    {
      'grid order-first md:col-span-1 md:order-last': rightSideImage
    },
    {
      'w-full relative': !leftSideImage && !rightSideImage && !bottomSideImage
    }
  );

  const cardBodyHolder = cx(
    {
      'px-10 sm:px-6 md:px-9 lg:px-10 py-10 last:pt-6':
        !rightSideImage && !leftSideImage
    },
    {
      'align-middle flex flex-col justify-center p-10 lg:p-10 md:p-7':
        leftSideImage || rightSideImage
    },
    {
      'overflow-auto sm:h-[250px]': bottomSideImage
    }
  );
  return {
    containerClass,
    imageHolderClasses,
    cardBodyHolder
  };
};

export const getCTAVariant = brandColor => {
  {
    switch (brandColor) {
      case 'Blue':
        return 'primary';
      case 'Black':
        return 'primary-black';
      case 'Black Outlined':
        return 'secondary-black';
      default:
        return 'secondary';
    }
  }
};
export const paramsToObject = entries => {
  const result = {};
  for (const [key, value] of entries) {
    // each 'entry' is a [key, value] tupple
    if (result[key]) {
      result[key].push(value);
    } else {
      result[key] = [value];
    }
  }
  return result;
};

import React, { memo } from 'react';
import { Slide, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import cx from 'classnames';
import PropTypes from 'prop-types';
import Header from '@components/header';
import { getNavigationData } from '@utils/index';
import { navigationDataFilter } from '@constants/index';

const Footer = dynamic(() => import('@components/footer'), { ssr: false });
const ContentfulPreviewBanner = dynamic(
  () => import('@components/contentfulPreviewBanner'),
  { ssr: false }
);

/**
 * @class
 * @param {Object} headerData Header Navigation data
 * @param {Object} footerData Footer Navigation data
 * @param {Object} locales Locales data for headers
 * @param {Object} children Children of the Layout
 *
 * @returns Layout Component as per the header, footer and locales data
 */

const Layout = ({
  isHeroWithHeading,
  headerData,
  footerData,
  locales,
  testId,
  slug,
  overlay,
  handleSearchOpen,
  children
}) => {
  const router = useRouter();
  const { primaryNavHeaderData, secondaryNavHeaderData } = getNavigationData(
    headerData,
    navigationDataFilter.header
  );
  const { primaryNavFooterData, secondaryNavFooterData, copyright } =
    getNavigationData(footerData, navigationDataFilter.footer);

  return (
    <>
      <a className='skip-to-content-link' href='#main'>
        Skip to main content
      </a>
      {router?.isPreview && <ContentfulPreviewBanner />}
      <div
        className='flex flex-col min-h-screen justify-between'
        data-testid={testId}
      >
        {(primaryNavHeaderData?.length > 0 ||
          secondaryNavHeaderData?.length > 0) && (
          <Header
            isHeroWithHeading={isHeroWithHeading}
            primaryNavHeaderData={primaryNavHeaderData}
            secondaryNavHeaderData={secondaryNavHeaderData}
            locales={locales}
            overlay={overlay}
            handleSearchOpen={handleSearchOpen}
          />
        )}
        <main
          id='main'
          className={cx('mb-auto animate__animated animate__fadeIn', slug)}
        >
          {children}
        </main>
        {(primaryNavFooterData?.length > 0 ||
          secondaryNavFooterData?.length > 0) && (
          <Footer
            primaryNavFooterData={primaryNavFooterData}
            secondaryNavFooterData={secondaryNavFooterData}
            copyright={copyright}
          />
        )}
      </div>
      <ToastContainer
        position='top-right'
        transition={Slide}
        autoClose={2500}
        hideProgressBar={true}
        pauseOnHover={false}
        pauseOnFocusLoss={false}
        newestOnTop
        closeOnClick={false}
        draggable={false}
        draggablePercent={0}
        rtl={false}
        theme='dark'
      />
    </>
  );
};

Layout.PropTypes = {
  isHeroWithHeading: PropTypes.bool,
  headerData: PropTypes.object,
  footerData: PropTypes.object,
  locales: PropTypes.object,
  testId: PropTypes.string,
  slug: PropTypes.string,
  overlay: PropTypes.any,
  handleSearchOpen: PropTypes.func,
  children: PropTypes.node.isRequired
};

export default memo(Layout);

import { parsePage } from '@lib/pageParsers';
import { contentfulPreviewClient, contentfulClient } from './client';

/**
 * Get contentful client based on preview parameter
 * @param {Boolean} preview - Preview
 * @returns {ContentfulClient} Contentful client
 */

export const getClient = preview =>
  preview ? contentfulPreviewClient : contentfulClient;

/**
 * Get contentful page query based on parameters
 * @param {Object} params - Query params
 * @returns {Object} Query object
 */
const getPageQuery = params => ({
  limit: 1,
  include: 10,
  locale: params.locale,
  'fields.slug': params.slug,
  content_type: params.pageContentType,
  locale: 'en-US',
  'metadata.tags.sys.id[in]': 'homeEnergy'
});

/**
 * Retrieves page data by Params.
 * @async
 * @method
 * @param {Object} params - Query params
 * @returns {Page} Page data object
 * @returns {null} When the page is not found.
 */
export async function getPage(params) {
  const query = getPageQuery(params);
  const { items } = await getClient(params.preview).getEntries(query);
  const page = items[0];

  return page ? parsePage(page) : null;
}

/**
 * Retrieves specific content type pages data by Params.
 * @async
 * @method
 * @param {Object} params - Query params
 * @returns {Array} Page data objects array
 * @returns {Array} Blank array When the page is not found.
 */
export async function getPagesOfType(params) {
  const { pageContentType, locale } = params;
  const client = getClient(params.preview);

  const { items: pages } = await client.getEntries({
    limit: 100,
    locale,
    content_type: pageContentType,
    locale: 'en-US',
    'metadata.tags.sys.id[in]': 'homeEnergy'
  });

  return pages ? pages.map(page => parsePage(page)) : [];
}

/**
 * Retrieves all pages data by Params.
 * @async
 * @method
 * @param {Text} pageContentType - Page content type
 * @returns {Array} Page data objects array
 * @returns {Array} Blank array When the page is not found.
 */
export async function getAllPages(pageContentType, preview = true) {
  const client = getClient(preview);

  const { items: pages } = await client.getEntries({
    limit: 100,
    content_type: pageContentType,
    locale: 'en-US',
    'metadata.tags.sys.id[in]': 'homeEnergy'
  });

  return pages ? pages.map(page => parsePage(page)) : [];
}

/**
 * Retrieves locales.
 * @async
 * @method
 * @returns {Array} locales array
 * @returns {Array} default locales array When the locale is not found.
 */
export async function getLocales() {
  // const { items: locales } = await getClient(false).getLocales();
  // locales
  //   ? locales.map(obj => {
  //       const localeArr = obj.name.split('(');
  //       return {
  //         label: `${localeArr[1].split(')')[0]} - ${localeArr[0]}`,
  //         code: obj.code
  //       };
  //     })
  return [
    {
      label: 'United States - English',
      icon: 'https://cdn.britannica.com/33/4833-004-828A9A84/Flag-United-States-of-America.jpg',
      code: 'en-US'
    }
  ];
}

/**
 * Retrieves header data.
 * @async
 * @method
 * @returns {Array} header data array
 */
export async function getHeader(preview = false) {
  let headerData = {};
  try {
    const response = await getClient(preview).getEntries({
      content_type: 'header',
      'metadata.tags.sys.id[in]': 'homeEnergy',
      include: 7
    });
    if (response?.items) {
      headerData = response.items;
    }
  } catch (error) {}
  return {
    headerData: headerData?.[0]?.fields || {}
  };
}

/**
 * Retrieves footer data.
 * @async
 * @method
 * @returns {Array} footer data array
 */
export async function getFooter(preview = false) {
  let footerData = {};
  try {
    const response = await getClient(preview).getEntries({
      content_type: 'footer',
      'metadata.tags.sys.id[in]': 'homeEnergy',
      include: 7
    });
    if (response?.items) {
      footerData = response.items;
    }
  } catch (error) {}
  return {
    footerData: footerData?.[0]?.fields || {}
  };
}

import { availableLocales, UnknownLocale } from '@constants/index';

export function findLocale(current) {
  return availableLocales.find(locale => current === locale);
}

/**
 * Returns UnknownLocale if the provided locale isn't one that can be used
 * @param locale
 */
export const getLocale = (locale = 'en-US') => {
  const actualLocale = findLocale(String(locale));
  return actualLocale ?? UnknownLocale;
};

export const withLocale = fn => {
  return context => {
    const locale = getLocale(context?.params?.locale);

    if (locale === UnknownLocale || !locale) {
      return {
        notFound: true
      };
    } else {
      return fn(locale, context);
    }
  };
};

export const getip = async () => {
  return await fetch(`https://ifconfig.me/ip`);
};

export const getlocation = async coords => {
  return await fetch(
    `https://dev.virtualearth.net/REST/v1/Locations/${coords.latitude},${coords.longitude}?output=json&key=${process.env.NEXT_PUBLIC_BING_MAPS}`
  );
};

export const getLocationFromIP = async ip => {
  return await fetch(`https://ipapi.co/${ip}/json/`);
};

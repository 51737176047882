export const DropSvg = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 24 24'
    strokeWidth={1.5}
    stroke='currentColor'
    className='w-6 h-6 mr-4 text-gray-600'
  >
    <path
      strokeLinecap='round'
      strokeLinejoin='round'
      d='M19.5 8.25l-7.5 7.5-7.5-7.5'
    />
  </svg>
);
export const DropSvgOpen = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 24 24'
    strokeWidth={1.5}
    stroke='currentColor'
    className='w-6 h-6 mr-4 text-gray-600'
  >
    <path
      strokeLinecap='round'
      strokeLinejoin='round'
      d='M8.25 4.5l7.5 7.5-7.5 7.5'
    />
  </svg>
);

export const DownloadIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 24 24'
    strokeWidth={1.5}
    stroke='gray'
    className='w-6 h-6'
  >
    <path
      d='M20 15V18C20 19.1046 19.1046 20 18 20H6C4.89543 20 4 19.1046 4 18L4 15M8 11L12 15M12 15L16 11M12 15V3'
      stroke='#000000'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export const Fb = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    width='8px'
    height='20px'
    viewBox='0 0 8 17'
    version='1.1'
  >
    <defs>
      <path
        d='M4.788,23.2 L1.595,23.2 L1.595,15.09898 L3.41504602e-13,15.09898 L3.41504602e-13,12.30764 L1.595,12.30764 L1.595,10.63181 C1.595,8.35477 2.496,7 5.055,7 L7.185,7 L7.185,9.79236 L5.854,9.79236 C4.858,9.79236 4.792,10.18243 4.792,10.91043 L4.787,12.30764 L7.2,12.30764 L6.918,15.09898 L4.787,15.09898 L4.787,23.2 L4.788,23.2 Z'
        id='path-2'
      />
    </defs>
    <g
      id='PDP-Full-Layout'
      stroke='none'
      strokeWidth='1'
      fill='none'
      fillRule='evenodd'
    >
      <g
        id='D_Kohler_UI_Batch-1,-Product-Detail,-Share-1.2'
        transform='translate(-1242.000000, -190.000000)'
      >
        <g id='Share-Widget' transform='translate(1173.000000, 164.000000)'>
          <g id='Social-Icons' transform='translate(25.000000, 20.000000)'>
            <g
              id='05-Iconography/Social/Facebook'
              transform='translate(44.000000, 0.000000)'
            >
              <mask id='mask-2' fill='white'>
                <use xlinkHref='#path-2' />
              </mask>
              <use id='Social-Icon' fill='#000000' xlinkHref='#path-2' />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
export const Twitter = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    width='21px'
    height='19px'
    viewBox='0 0 19 17'
    version='1.1'
  >
    <defs>
      <path
        d='M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z'
        id='path-1'
      />
    </defs>
    <g
      id='PDP-Full-Layout'
      stroke='none'
      strokeWidth='1'
      fill='none'
      fillRule='evenodd'
    >
      <g
        id='D_Kohler_UI_Batch-1,-Product-Detail,-Share-1.2'
        transform='translate(-1198.000000, -182.000000)'
      >
        <g id='Share-Widget' transform='translate(1173.000000, 164.000000)'>
          <g
            id='05-Iconography/Social/Twitter'
            transform='translate(25.000000, 20.000000)'
          >
            <mask id='mask-2' fill='white'>
              <use xlinkHref='#path-1' />
            </mask>
            <use id='Social-Icon' fill='#000000' xlinkHref='#path-1' />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
export const Copy = () => (
  <svg
    width='20px'
    height='20px'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    stroke='#000000'
  >
    <path
      d='M17.1109 13.5754C17.5014 13.9659 18.1346 13.9659 18.5251 13.5754L21 11.1005C23.1479 8.95265 23.1479 5.47025 21 3.32236C18.8521 1.17448 15.3697 1.17448 13.2218 3.32236L10.7469 5.79724C10.3564 6.18776 10.3564 6.82093 10.7469 7.21145C11.1375 7.60197 11.7706 7.60197 12.1612 7.21145L14.636 4.73658C16.0029 3.36974 18.2189 3.36974 19.5858 4.73658C20.9526 6.10341 20.9526 8.31949 19.5858 9.68632L17.1109 12.1612C16.7204 12.5517 16.7204 13.1849 17.1109 13.5754Z'
      fill='#0F0F0F'
    ></path>{' '}
    <path
      d='M5.79719 10.747C6.18771 10.3565 6.82088 10.3565 7.2114 10.747C7.60193 11.1375 7.60193 11.7707 7.2114 12.1612L4.73653 14.6361C3.36969 16.0029 3.36969 18.219 4.73653 19.5858C6.10336 20.9527 8.31944 20.9527 9.68628 19.5858L12.1612 17.1109C12.5517 16.7204 13.1848 16.7204 13.5754 17.1109C13.9659 17.5015 13.9659 18.1346 13.5754 18.5252L11.1005 21C8.95261 23.1479 5.4702 23.1479 3.32232 21C1.17443 18.8521 1.17443 15.3697 3.32232 13.2219L5.79719 10.747Z'
      fill='#0F0F0F'
    ></path>{' '}
    <path
      d='M8.97917 15.3432C8.58865 14.9527 8.58865 14.3195 8.97917 13.929L13.9289 8.97922C14.3194 8.58869 14.9526 8.58869 15.3431 8.97922C15.7337 9.36974 15.7337 10.0029 15.3431 10.3934L10.3934 15.3432C10.0029 15.7337 9.3697 15.7337 8.97917 15.3432Z'
      fill='#0F0F0F'
    ></path>
  </svg>
);
export const Pintrest = () => (
  <svg
    stroke='currentColor'
    fill='currentColor'
    strokeWidth='0'
    viewBox='0 0 448 512'
    height='42'
    width='42'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path d='M448 80v352c0 26.5-21.5 48-48 48H154.4c9.8-16.4 22.4-40 27.4-59.3 3-11.5 15.3-58.4 15.3-58.4 8 15.3 31.4 28.2 56.3 28.2 74.1 0 127.4-68.1 127.4-152.7 0-81.1-66.2-141.8-151.4-141.8-106 0-162.2 71.1-162.2 148.6 0 36 19.2 80.8 49.8 95.1 4.7 2.2 7.1 1.2 8.2-3.3.8-3.4 5-20.1 6.8-27.8.6-2.5.3-4.6-1.7-7-10.1-12.3-18.3-34.9-18.3-56 0-54.2 41-106.6 110.9-106.6 60.3 0 102.6 41.1 102.6 99.9 0 66.4-33.5 112.4-77.2 112.4-24.1 0-42.1-19.9-36.4-44.4 6.9-29.2 20.3-60.7 20.3-81.8 0-53-75.5-45.7-75.5 25 0 21.7 7.3 36.5 7.3 36.5-31.4 132.8-36.1 134.5-29.6 192.6l2.2.8H48c-26.5 0-48-21.5-48-48V80c0-26.5 21.5-48 48-48h352c26.5 0 48 21.5 48 48z'></path>
  </svg>
);

export const LinkdIn = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    width='18px'
    height='18px'
    viewBox='0 0 350 350'
    version='1.1'
  >
    <g
      id='PDP-Full-Layout'
      stroke='none'
      strokeWidth='1'
      fill='none'
      fillRule='evenodd'
    >
      <g
        id='D_Kohler_UI_Batch-1,-Product-Detail,-Share-1.2'
        transform='translate(-1406.000000, -140.000000)'
        fill='#000000'
      >
        <g id='Share-Widget' transform='translate(1173.000000, 164.000000)'>
          <g id='Social-Icons' transform='translate(25.000000, 20.000000)'>
            <g
              id='05-Iconography/Social/LinkedIn'
              transform='translate(208.000000, 0.000000)'
            >
              <path
                id='Social-Icon'
                d='M72.16,99.73H9.927c-2.762,0-5,2.239-5,5v199.928c0,2.762,2.238,5,5,5H72.16c2.762,0,5-2.238,5-5V104.73 C77.16,101.969,74.922,99.73,72.16,99.73z'
              />
              <path
                id='Social-Icon'
                d='M41.066,0.341C18.422,0.341,0,18.743,0,41.362C0,63.991,18.422,82.4,41.066,82.4 c22.626,0,41.033-18.41,41.033-41.038C82.1,18.743,63.692,0.341,41.066,0.341z'
              />
              <path
                id='Social-Icon'
                d='M230.454,94.761c-24.995,0-43.472,10.745-54.679,22.954V104.73c0-2.761-2.238-5-5-5h-59.599 c-2.762,0-5,2.239-5,5v199.928c0,2.762,2.238,5,5,5h62.097c2.762,0,5-2.238,5-5v-98.918c0-33.333,9.054-46.319,32.29-46.319 c25.306,0,27.317,20.818,27.317,48.034v97.204c0,2.762,2.238,5,5,5H305c2.762,0,5-2.238,5-5V194.995 C310,145.43,300.549,94.761,230.454,94.761z'
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
export const FilterSvg = () => (
  <svg
    width='19px'
    height='19px'
    //style={{ paddingTop: '16px' }}
    viewBox='0 0 24.00 24.00'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    stroke='#000000'
  >
    <path
      d='M15 10.5A3.502 3.502 0 0 0 18.355 8H21a1 1 0 1 0 0-2h-2.645a3.502 3.502 0 0 0-6.71 0H3a1 1 0 0 0 0 2h8.645A3.502 3.502 0 0 0 15 10.5zM3 16a1 1 0 1 0 0 2h2.145a3.502 3.502 0 0 0 6.71 0H21a1 1 0 1 0 0-2h-9.145a3.502 3.502 0 0 0-6.71 0H3z'
      fill='#000000'
    ></path>
  </svg>
);
export const Pdf = () => (
  <svg
    style={{ color: 'red', marginTop: '3px' }}
    xmlns='http://www.w3.org/2000/svg'
    width='17'
    height='17'
    fill='currentColor'
    viewBox='0 0 16 16'
  >
    <path
      d='M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z'
      fill='red'
    ></path>
    <path
      d='M4.603 12.087a.81.81 0 0 1-.438-.42c-.195-.388-.13-.776.08-1.102.198-.307.526-.568.897-.787a7.68 7.68 0 0 1 1.482-.645 19.701 19.701 0 0 0 1.062-2.227 7.269 7.269 0 0 1-.43-1.295c-.086-.4-.119-.796-.046-1.136.075-.354.274-.672.65-.823.192-.077.4-.12.602-.077a.7.7 0 0 1 .477.365c.088.164.12.356.127.538.007.187-.012.395-.047.614-.084.51-.27 1.134-.52 1.794a10.954 10.954 0 0 0 .98 1.686 5.753 5.753 0 0 1 1.334.05c.364.065.734.195.96.465.12.144.193.32.2.518.007.192-.047.382-.138.563a1.04 1.04 0 0 1-.354.416.856.856 0 0 1-.51.138c-.331-.014-.654-.196-.933-.417a5.716 5.716 0 0 1-.911-.95 11.642 11.642 0 0 0-1.997.406 11.311 11.311 0 0 1-1.021 1.51c-.29.35-.608.655-.926.787a.793.793 0 0 1-.58.029zm1.379-1.901c-.166.076-.32.156-.459.238-.328.194-.541.383-.647.547-.094.145-.096.25-.04.361.01.022.02.036.026.044a.27.27 0 0 0 .035-.012c.137-.056.355-.235.635-.572a8.18 8.18 0 0 0 .45-.606zm1.64-1.33a12.647 12.647 0 0 1 1.01-.193 11.666 11.666 0 0 1-.51-.858 20.741 20.741 0 0 1-.5 1.05zm2.446.45c.15.162.296.3.435.41.24.19.407.253.498.256a.107.107 0 0 0 .07-.015.307.307 0 0 0 .094-.125.436.436 0 0 0 .059-.2.095.095 0 0 0-.026-.063c-.052-.062-.2-.152-.518-.209a3.881 3.881 0 0 0-.612-.053zM8.078 5.8a6.7 6.7 0 0 0 .2-.828c.031-.188.043-.343.038-.465a.613.613 0 0 0-.032-.198.517.517 0 0 0-.145.04c-.087.035-.158.106-.196.283-.04.192-.03.469.046.822.024.111.054.227.09.346z'
      fill='red'
    ></path>
  </svg>
);
export const MenuIcon = () => (
  <div className='space-y-2' style={{ marginTop: '20px' }}>
    <div className='w-8 h-0.5 bg-gray-600'></div>
    <div className='w-8 h-0.5 bg-gray-600'></div>
    <div className='w-8 h-0.5 bg-gray-600'></div>
  </div>
);
export const UpArrow = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='16'
    height='16'
    fill='currentColor'
    className='bi bi-arrow-up'
    viewBox='0 0 16 16'
  >
    <path d='M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z' />{' '}
  </svg>
);
export const PlusSvg = () => (
  <svg
    stroke='#888'
    fill='none'
    strokeWidth='1.5'
    viewBox='0 0 24 24'
    height='20'
    width='20'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path d='M12 19V5'></path>
    <path d='M5 12H19'></path>
  </svg>
);

export const MinusSvg = () => (
  <svg
    stroke='#888'
    fill='none'
    strokeWidth='1.5'
    viewBox='0 0 24 24'
    height='20'
    width='20'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path d='M19 12H5'></path>
  </svg>
);

export const PrevSvg = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    className='icon icon-tabler icon-tabler-chevron-left'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    strokeWidth='2'
    stroke='grey'
    fill='none'
    strokeLinecap='round'
    strokeLinejoin='round'
  >
    <circle cx='12' cy='12' r='10' fill='#f9f9f9' stroke='#f9f9f9' />
    <path d='M15 6l-6 6l6 6'></path>
  </svg>
);

export const NextSvg = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    className='icon icon-tabler icon-tabler-chevron-right'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    strokeWidth='2'
    stroke='grey'
    fill='none'
    strokeLinecap='round'
    strokeLinejoin='round'
  >
    <circle cx='12' cy='12' r='10' fill='#f9f9f9' stroke='#f9f9f9' />
    <path d='M9 6l6 6l-6 6'></path>
  </svg>
);

export const Email = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    width='28px'
    height='20px'
    viewBox='0 0 28 20'
    version='1.1'
  >
    <g id='Page-1' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g id='Email' fill='#000000' fillRule='nonzero'>
        <path d='M28,0 L28,20 L0,20 L0,0 L28,0 Z M26.727,2.086 L13.971271,12.135221 L1.272,2.087 L1.272727,18.75 L26.727273,18.75 L26.727,2.086 Z M25.753,1.25 L2.242,1.25 L13.972,10.53125 L25.753,1.25 Z' />
      </g>
    </g>
  </svg>
);
export const Email1 = () => (
  <svg
    style={{ marginTop: '0px' }}
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    width='18px'
    height='18px'
    viewBox='0 0 28 20'
    version='1.1'
  >
    <g id='Page-1' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g id='Email' fill='#000000' fillRule='nonzero'>
        <path d='M28,0 L28,20 L0,20 L0,0 L28,0 Z M26.727,2.086 L13.971271,12.135221 L1.272,2.087 L1.272727,18.75 L26.727273,18.75 L26.727,2.086 Z M25.753,1.25 L2.242,1.25 L13.972,10.53125 L25.753,1.25 Z' />
      </g>
    </g>
  </svg>
);

export const CopyLink = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    width='20px'
    height='20px'
    viewBox='0 0 20 20'
    version='1.1'
  >
    <g
      id='PDP-Full-Layout'
      stroke='none'
      strokeWidth='1'
      fill='none'
      fillRule='evenodd'
    >
      <g
        id='D_Kohler_UI_Batch-1,-Product-Detail,-Share-1.2'
        transform='translate(-1198.000000, -247.000000)'
        fill='#000000'
      >
        <g id='Share-Widget' transform='translate(1173.000000, 164.000000)'>
          <g
            id='Icon/Lockup-with-Type/Copy-Link'
            transform='translate(25.000000, 81.000000)'
          >
            <path
              d='M5.90517726,9.85509285 L2.3953292,13.3649409 C0.672275371,15.0879947 0.672275371,17.881617 2.3953292,19.6046708 C4.06616928,21.2755109 6.74367789,21.3261424 8.47552819,19.7565654 L8.63505909,19.6046708 L12.1449071,16.0948227 L12.9248734,16.874789 L9.41502532,20.384637 C7.26120804,22.5384543 3.76918025,22.5384543 1.61536297,20.384637 C-0.481774919,18.2874991 -0.536962759,14.9216562 1.44979945,12.7577443 L1.61536297,12.5849747 L5.12521103,9.07512662 L5.90517726,9.85509285 Z M14.0948227,8.6851435 L7.07512662,15.7048396 L6.29516038,14.9248734 L13.3148565,7.90517726 L14.0948227,8.6851435 Z M18.384637,3.61536297 C20.4731871,5.70391306 20.5364765,9.05079883 18.5745052,11.2156117 L18.384637,11.4150253 L14.874789,14.9248734 L14.0948227,14.1449071 L17.6046708,10.6350591 L17.7565654,10.4755282 C19.3261424,8.74367789 19.2755109,6.06616928 17.6046708,4.3953292 C15.9390521,2.7297105 13.2730467,2.67418988 11.5407356,4.22876733 L11.3649409,4.3953292 L7.85509285,7.90517726 L7.07512662,7.12521103 L10.5849747,3.61536297 L10.7577443,3.44979945 C12.9216562,1.46303724 16.2874991,1.51822508 18.384637,3.61536297 Z'
              id='Link-Icon'
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

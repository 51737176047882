import { HiSearch } from 'react-icons/hi';

const ProductSearch = () => {
  return (
    <div className='relative flex flex-col mr-3 my-2 cursor-pointer'>
      <div className='pl-10 pr-4 py-2'>
        <div className='absolute inset-y-0 left-0 pl-3 flex items-center'>
          <HiSearch />
        </div>
        Search
      </div>
    </div>
  );
};

export default ProductSearch;

import Logger from '@services/logger/loggerService';

class LocalStorageService {
  // Get item from localStorage
  static getItem(key) {
    try {
      return JSON.parse(window.localStorage.getItem(key));
    } catch (error) {
      Logger.error(`Error getting item ${key} from localStorage`, error);
    }
  }

  // Set item in localStorage
  static setItem(key, value) {
    try {
      window.localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
      Logger.error(`Error storing item ${key} to localStorage`, error);
    }
  }

  // Remove item from localStorage
  static removeItem(key) {
    try {
      window.localStorage.removeItem(key);
    } catch (error) {
      Logger.error(`Error removing item ${key} from localStorage`, error);
    }
  }

  // clear localStorage
  static clear() {
    try {
      window.localStorage.clear();
    } catch (error) {
      Logger.error(`Error clearing localStorage`, error);
    }
  }
}

export default LocalStorageService;

import { defaultLocale } from '@constants/index';
import { findLocale } from './locales';

/**
 * Retrieves initial locale data.
 * @async
 * @method
 * @returns {Array} Locales
 */

export const getInitialLocale = () => {
  const previousLocale = localStorage.getItem('locale');
  const browserLocale = navigator.language;
  if (!previousLocale) {
    localStorage.setItem('locale', navigator.language);
  }
  const possibleLocales = [previousLocale, browserLocale];

  return possibleLocales.find(locale => findLocale(locale)) ?? defaultLocale;
};

const Logger = {
  log: (...params) => {
    if (process.env.NODE_ENV != 'production') {
      console.log(...params);
    }
  },

  error: (...params) => {
    if (process.env.NODE_ENV != 'production') {
      console.error(...params);
    }
  }
};

export default Logger;

import React, { useState } from 'react';
import Image from 'next/image';
import cx from 'classnames';
import { ChevronDownIcon } from '@heroicons/react/24/solid';
import { onEnterSpacePress } from '@utils/keyboard';

/**
 * @class
 * @param {string} className Specifies classname of dropdown
 * @param {string} label label on dwopdown
 * @param {Array} options options array to show dropdown elements
 * @param {Object} icon icon for dropdown
 * @param {Boolean} hideDownIcon prop to hide down icon on dropdown
 *
 * @returns Dropdown Component as per the options
 */

export default function Dropdown({
  className,
  label,
  options,
  icon,
  hideDownIcon = false
}) {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const closeDropdownOpen = (event, dropState = false) => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      setDropdownOpen(dropState);
    }
  };

  const ulClasses = cx(
    'list-none',
    'top-[48px]',
    'right-0',
    'bg-white',
    'shadow-lg',
    'border',
    'absolute',
    'z-10',
    'rounded',
    'text-gray-700',
    'mt-2',
    'w-auto',
    'flex-col',
    'pl-0',
    {
      flex: dropdownOpen
    },
    {
      hidden: !dropdownOpen
    }
  );

  return (
    <div
      onKeyDown={e => {
        if (e.key === 'Enter' || e.key === '') {
          closeDropdownOpen(e, !dropdownOpen);
        }
      }}
      onClick={e => closeDropdownOpen(e, !dropdownOpen)}
      className={cx('flex font-Roman text-sm', className)}
      onBlur={e => closeDropdownOpen(e)}
    >
      <button
        className='inline-flex items-center pl-0 pr-0  bg-transparent border-0 rounded cursor-pointer'
        aria-label='dropdown'
        data-testid={'dropdown'}
        aria-haspopup='true'
        aria-expanded={dropdownOpen}
        onClick={e => closeDropdownOpen(e, !dropdownOpen)}
      >
        <span>{icon}</span>
        {label ? (
          <span className='flex'>
            <label className='flex text-white align-baseline cursor-pointer'>
              {label}
            </label>
          </span>
        ) : null}
        {!hideDownIcon ? (
          <ChevronDownIcon className='w-5 h-5 mr-2 align-middle' />
        ) : null}
      </button>
      <ul className={ulClasses} data-testid='dropdownlist'>
        {options?.map((ele, index) => (
          <li
            className={cx(
              'cursor-pointer flex',
              {
                'm-3': options.length === 0
              },
              {
                'mx-3': options.length > 0
              },
              {
                'mt-3': options.length > 0 && index === 0
              },
              {
                'mb-3': options.length > 0 && index === options.length - 1
              }
            )}
            onKeyDown={onEnterSpacePress(ele.action)}
            onClick={ele.action}
            key={ele.label}
          >
            <div
              role='button'
              tabIndex={0}
              onKeyDown={e =>
                (e.key === ' ' || e.key === 'Enter') && ele.action(e)
              }
              className='w-full flex select-none z-10 active:bg-gray-500 focus:bg-gray-500 hover:bg-gray-500 active:text-white focus:text-white hover:text-white'
            >
              {ele.icon ? (
                <span className='p-2'>
                  <Image
                    alt={ele.label}
                    className='h-auto sm:h-5 sm:w-6 outline-none'
                    height='5'
                    width='96'
                    src={ele.icon}
                    priority={true}
                  />
                </span>
              ) : null}
              <span className='p-2'>{ele.label}</span>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}

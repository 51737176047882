import LocalStorageService from '@services/localStorage/localStorageService';
import Logger from '@services/logger/loggerService';
import axios from 'axios';
import jwtDecode from 'jwt-decode';

// Read configuration from environment variable
const isProd = process.env.NODE_ENV === 'production';
const baseURL = !isProd
  ? 'http://localhost:3000/api/proxy'
  : process.env.NEXT_PUBLIC_API_BASE_URL;

const ApiService = axios.create({
  baseURL,
  timeout: process.env.NEXT_PUBLIC_API_TIMEOUT || 5000
});

// Function to check token expiry
function isTokenExpired(expiryTime) {
  const currentTime = Math.floor(Date.now() / 1000);
  return currentTime >= expiryTime;
}

// Function to fetch a new guest token
export async function fetchGuestToken() {
  const response = await axios.post(`${baseURL}/oauth/token?scope=guest`);
  const decodedToken = jwtDecode(response.data.access_token);
  const expiryTime = decodedToken.exp;
  const tokenData = {
    token: response.data.access_token,
    expiryTime
  };
  LocalStorageService.setItem('guestToken', tokenData);
  return tokenData.token;
}

// Function to get the appropriate token
export const getAuthToken = async () => {
  // Check the localStorage for user token
  let tokenData = LocalStorageService.getItem('userToken');

  // If user token is not found or expired, use guest token
  if (!tokenData || isTokenExpired(tokenData.expiryTime)) {
    tokenData = LocalStorageService.getItem('guestToken');

    // If guest token is not found or expired, fetch a new one
    if (!tokenData || isTokenExpired(tokenData.expiryTime)) {
      return await fetchGuestToken();
    }
  }

  return tokenData.token;
};

// Add a request interceptor to insert the authorization header
ApiService.interceptors.request.use(
  async config => {
    const token = await getAuthToken();
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  error => {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor to handle API errors
ApiService.interceptors.response.use(
  response => {
    // Do something with the response data
    return response;
  },
  error => {
    // Do something with the response error
    Logger.error('Response error: ', error);
    return Promise.reject(error);
  }
);

export default ApiService;

import { getAuthToken } from '@services/api/apiService';

export async function getProductDetails(lang, value) {
  try {
    const apiBaseURL = process.env.API_BASEURL;
    const res = await fetch(
      `${apiBaseURL}/api/pdpv2?lang=${lang}&value=${value.toUpperCase()}`,
      {
        method: 'GET',
        headers: {
          mode: 'no-cors',
          Authorization: process.env.API_AUTH,
          'Access-Control-Allow-Origin': '*'
        }
      }
    );
    const data = await res.json();
    return data;
  } catch (err) {
    console.log(err);
  }
}

export async function getProductListing(requestBody) {
  try {
    const token = await getAuthToken();

    const apiBaseURL = process.env.API_BASEURL;
    const res = await fetch(`${apiBaseURL}/api/products`, {
      method: 'POST',
      headers: {
        mode: 'no-cors',
        Authorization: `Bearer ${token}`,
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(requestBody)
    });
    const data = await res.json();
    console.log('front end getting res ', data);
    return data;
  } catch (err) {
    console.log(err);
  }
}
export async function getSearchResults(requestBody) {
  try {
    const token = await getAuthToken();

    const apiBaseURL = process.env.API_BASEURL;
    const res = await fetch(`${apiBaseURL}/api/suggestions`, {
      method: 'POST',
      headers: {
        mode: 'no-cors',
        Authorization: `Bearer ${token}`,
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(requestBody)
    });
    const data = await res.json();
    console.log('front end getting res ');
    return data;
  } catch (err) {
    console.log(err);
  }
}
export async function getManualData() {
  try {
    const apiBaseURL = process.env.API_BASEURL;
    const res = await fetch(`${apiBaseURL}/api/manuals`, {
      method: 'GET',
      headers: {
        mode: 'no-cors',
        Authorization: process.env.API_AUTH,
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json'
      }
    });
    const data = await res.json();
    console.log('front end getting res get Manual Data');
    return data;
  } catch (err) {
    console.log(err);
  }
}

export async function getManualDataForFuelType(fueltype) {
  try {
    const apiBaseURL = process.env.API_BASEURL;
    const res = await fetch(`${apiBaseURL}/api/manuals?FuleType=${fueltype}`, {
      method: 'GET',
      headers: {
        mode: 'no-cors',
        Authorization: process.env.API_AUTH,
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json'
      }
    });
    const data = await res.json();
    console.log('front end getting res get Manual Data for Fule Type');
    return data;
  } catch (err) {
    console.log(err);
  }
}

export async function getManualDataPost(requestBody) {
  try {
    const apiBaseURL = process.env.API_BASEURL;
    const res = await fetch(`${apiBaseURL}/api/manuals`, {
      method: 'POST',
      headers: {
        mode: 'no-cors',
        Authorization: process.env.API_AUTH,
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(requestBody)
    });
    const data = await res.json();
    console.log('front end getting res ');
    return data;
  } catch (err) {
    console.log(err);
  }
}

export async function getYoutubeMetaData(id) {
  try {
    const res = await fetch(
      `https://www.googleapis.com/youtube/v3/videos?part=snippet&id=${id}&key=${process.env.YOUTUBE_SUBSCRIPTION_KEY}`
    );
    const data = await res.json();
    return data;
  } catch (err) {
    console.log(err);
  }
}

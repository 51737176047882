import { memo } from 'react';
import cx from 'classnames';
import { MapPinIcon } from '@heroicons/react/24/outline';
import PropTypes from 'prop-types';

function Location({ location, divider = true, classNames = {} }) {
  return (
    <>
      {location ? (
        <div className={cx('flex', classNames?.container)}>
          <MapPinIcon className={cx('h-5 text-white mr-3', classNames?.icon)} />
          <div
            className={cx(
              'text-white pt-1 mr-4 capitalize text-sm',
              classNames?.text
            )}
          >
            {location}
          </div>
          {divider ? (
            <div className='inline-block  min-h-[1em] w-0.5 mr-4 self-stretch bg-neutral-100 opacity-100 dark:opacity-50' />
          ) : null}
        </div>
      ) : null}
    </>
  );
}

Location.PropTypes = {
  location: PropTypes.string.isRequired,
  divider: PropTypes.bool,
  classNames: PropTypes.object
};

export default memo(Location);

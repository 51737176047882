import { toast } from 'react-toastify';
import { setIP, setLocation, setLocationIP } from '@redux/reducers/user';
import { getip, getlocation, getLocationFromIP } from '@services/customer';

export const getIP = dispatch => async () => {
  try {
    const result = await (await getip()).text();
    dispatch(setIP(result));
    return result;
  } catch (error) {
    if (error.message) {
      toast.error(error.message);
    }
    console.error(error);
  }
};

export const getLocation = dispatch => async body => {
  try {
    const result = await (await getlocation(body)).json();
    dispatch(setLocation(result));
  } catch (error) {
    if (error.message) {
      toast.error(error.message);
    }
    console.error(error);
  }
};

export const getLocationIP = dispatch => async body => {
  try {
    const result = await getLocationFromIP(body);
    const data = await result.json();
    if (data?.city) {
      dispatch(setLocationIP(data));
    }
  } catch (error) {
    if (error.message) {
      toast.error(error.message);
    }
    console.error(error);
  }
};
